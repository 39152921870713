import React, { useContext, useEffect } from 'react'
import { DistributionContext } from '.';
import Box from '@mui/material/Box';
import NumTruck from './numTruck';
import DatagridStep4 from './datagridStep4';
import DefaultModal from '../Utils/DefaultModal';
import ModalStep2 from './modalstep2';
import { sortTables } from './functionsCore';


const Step4 = () => {

  const { state, setState } = useContext(DistributionContext)

  const countTruckNumber = () => {
    const num_truck = state.consolidatedStep4.length

    setState({ numTruck: num_truck })
  }

  useEffect(() => {
    countTruckNumber()
  }, [])

  const closeModal = () => {
    setState({ modalStep2: false })
  }

  let trucks = state.consolidatedStep4.length

  return (
    <>
      <Box>
        <NumTruck />
      </Box>

      <DefaultModal dialogProps={{ maxWidth: "xl" }} open={state.modalStep2} handleClose={closeModal} content={<ModalStep2 />}
        title={' Estoque '} />

      {
        state.consolidatedStep4.map((item, index) => {
          const num = index + 1;
          const keyTB = `table${num}`
          const current = item[keyTB]

          let num_m3 = 0
          let total_cargo = 0
          let total_peso = 0
          let num_truck = 0
          for (const each of current.lines) {
            num_truck = parseInt(num_truck) + 1
            let convertedNumM3 = parseFloat(each.m3.replace(',', '.')) * parseInt(each.cargo)
            let convertedNumPeso = parseFloat(each.peso) * parseInt(each.cargo)

            num_m3 = convertedNumM3 + num_m3
            total_peso = convertedNumPeso + total_peso
            total_cargo = parseInt(total_cargo) + parseInt(each.cargo)


          }
          num_m3 = num_m3.toFixed(2)
          total_peso = total_peso.toFixed(2)
          const truck_size = item[keyTB]['truck_size']
          item[keyTB]['total_m3'] = num_m3

          const linesTruck = current.lines

          const sorted_lines = sortTables(linesTruck)

          return (

            <DatagridStep4 trucks_number={trucks} index={num} truck_size={truck_size} lines={sorted_lines} table={keyTB} m3={num_m3} peso={total_peso} envio={total_cargo} />

          );
        })
      }

    </>
  )

}

export default Step4;