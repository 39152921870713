import React, { useState, useEffect, useContext, createContext, useReducer } from 'react';
import { defaultReducer } from '../Utils/reducers';
import { Box, IconButton } from '@mui/material';
import { api } from '../../services/api';
import { externalComponent } from '../AppRoutes';
import DefaultModal from '../Utils/DefaultModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OrderFrame from './orderFrame';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import toast from 'react-hot-toast';
import ModalText from '../Distribuicao/modalText';
import ColorButton from '../Buttons/ColorButton';
import { hasPermissions } from '../Utils/groups';
import Toolbar from './toolbar';
import Justifications from './justifications';
import clsx from 'clsx';
import InputFileUpload from './attachment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AttachmentModal from './attachment_modal';
import Typography from '@mui/material/Typography';
import Table from '../Table';
import { ResponsiveActionBox } from '../Table/mobileTable';
import './style.css'
import { Style } from '@mui/icons-material';


let interval = 0
export const PainelContext = createContext()

const Painel = () => {

    const { setLoader, isMobile } = useContext(externalComponent)

    const [rowActual, setRowActual] = useState([])
    const [rowNext, setRowNext] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [orderNumber, setOrderNumber] = useState()
    const [modalStatus, setModalStatus] = useState(false)
    const [modalHide, setModalHide] = useState(false)
    const [modalRefuse, setModalRefuse] = useState(false)
    const [circleStatus, setCircleStatus] = useState(false)
    const [emailDesc, setEmailDesc] = useState('')
    const [justifications, setJustifications] = useState([])
    const [justificationList, setJustificationList] = useState([])
    const [otherMessage, setOtherMessage] = useState(false)
    const [tableSelect, setTableSelect] = useState('analisados')


    const [state, setState] = useReducer(defaultReducer, {

        message: '', modalAttachment: false, id_order: 1

    })

    const closeModal = () => {
        setOpenModal(false)
    }

    const openModalOrder = (num, emailDesc) => {
        setOrderNumber(num)
        setOpenModal(true)
        setEmailDesc(emailDesc)
    }

    const openModalHideOrder = (num, id_order) => {
        setOrderNumber(num)
        setModalHide(true)
        setState({ id_order: id_order })
    }

    const openModalRefuseOrder = (num) => {
        setOrderNumber(num)
        setJustificationList([])
        setOtherMessage(false)
        setModalRefuse(true)
    }

    const openModalAttachment = (id_order) => {
        setState({ id_order: id_order, modalAttachment: true })
    }

    const ordenedList = (list) => {
        return list.sort((a, b) => b.order_number - a.order_number);
    }

    const getCheckboxValue = (e, id) => {
        if (e.target.checked) {
            setJustificationList([...justificationList, id])
        } else {
            const newList = justificationList.filter(item => item !== id)
            setJustificationList(newList)
        }

        if (id === 0) {
            if (e.target.checked) {
                setOtherMessage(true)
            } else {
                setOtherMessage(false)
            }
        }
    }

    const refuseOrder = async () => {
        if (justificationList.length === 0) {
            toast.error('Você deve selecionar pelo menos uma justificativa.')
        } else {
            setLoader(true)
            const body = {
                "ids": justificationList,
                "numped": orderNumber,
                "message": state.message
            }
            const res = await api.post('/recusapedido/', body)
            if (res.status === 200) {
                setModalRefuse(false)
                toast.success('Pedido recusado com sucesso!')

            }
            setLoader(false)
            getActualOrder()
            getNextOrder()
        }
    }

    const columnsA = [

        {
            field: 'order_number',
            headerName: 'Pedido',
            flex: 0.3,
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis order_bold', {
                        selected: true,
                    });
                } else {
                    return clsx('order_bold', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'filial',
            headerName: 'Filial',
            flex: 0.2,
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'client',
            headerName: 'Cliente',
            flex: 0.6,
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'representative',
            headerName: 'Representante',
            flex: 0.7,
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'username',
            headerName: 'Usuário',
            flex: 0.4,
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'Descricao',
            headerName: 'Status',
            flex: 0.6,
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'pendency',
            headerName: 'Pendência',
            flex: 0.9,
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'attachments',
            headerName: 'Anexos',
            flex: 0.3,
            renderCell: (params) => (
                <Box>
                    <InputFileUpload id_order={params.row.id} getActualOrder={getActualOrder} />

                    {
                        params.row.anexos_count ?
                            <Tooltip title='Visualiza os anexo(s)'>
                                <AttachFileIcon className='actionIcon' sx={{ fontSize: '1.3rem' }} onClick={() => openModalAttachment(params.row.id)} />
                            </Tooltip>
                            :
                            <></>
                    }
                </Box>
            ),
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis center', {
                        selected: true,
                    });
                } else {
                    return clsx('center', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            flex: 0.4,
            renderCell: (params) => (
                <Actions numped={params.row.order_number} emailDesc={params.row.email_desc} category={true}
                    id_order={params.row.id} attachment={params.row.anexos_count}
                />
            ),
            cellClassName: (params) => {
                if (params.row.in_analysis) {
                    return clsx('analysis', {
                        selected: true,
                    });
                }
            }
        },

        {
            field: 'title',
            headerName: 'Title',
            hideTitle: true,
            renderCell: (params) => (
                <Box>
                    {params.row.order_number} - {params.row.representative} - {params.row.client}
                </Box>
            )
        }

    ]

    const columnsB = [

        {
            field: 'order_number',
            headerName: 'Pedido',
            flex: 0.3,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'filial',
            headerName: 'Filial',
            flex: 0.2,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'client',
            headerName: 'Cliente',
            flex: 0.6,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'representative',
            headerName: 'Representante',
            flex: 0.7,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'username',
            headerName: 'Usuário',
            flex: 0.4,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'Descricao',
            headerName: 'Status',
            flex: 0.6,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'pendency',
            headerName: 'Pendência',
            flex: 0.9,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            }
        },
        {
            field: 'attachments',
            headerName: 'Anexos',
            flex: 0.3,
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused center', {
                        selected: true,
                    });
                } else {
                    return clsx('center', {
                        selected: true,
                    });
                }
            },
            renderCell: (params) => (
                <Box>
                    {
                        params.row.anexos_count ?
                            <Tooltip title='Visualiza os anexo(s)'>
                                <AttachFileIcon className='actionIcon' sx={{ fontSize: '1.3rem' }} onClick={() => openModalAttachment(params.row.id)} />
                            </Tooltip>
                            :
                            <></>
                    }
                </Box>
            )
        },
        {
            field: 'acoes',
            headerName: 'Ações',
            cellClassName: (params) => {
                if (params.row.refused) {
                    return clsx('refused', {
                        selected: true,
                    });
                }
            },
            renderCell: (params) => (

                <Actions numped={params.row.order_number} emailDesc={params.row.email_desc} category={false}
                    id_order={params.row.id} attachment={params.row.anexos_count}
                />
            )
        },


        {
            field: 'title',
            headerName: 'Title',
            hideTitle: true,
            renderCell: (params) => (
                <Box>
                    {params.row.order_number} - {params.row.representative} - {params.row.client}
                </Box>
            )
        }



    ]

    const Actions = ({ numped, emailDesc, category, id_order, attachment }) => {
        return (
            <ResponsiveActionBox>
                {
                    category ?
                        hasPermissions('administrador', 'LiberacoesPedido') ?
                            <Tooltip title='Libera o pedido'>
                                <IconButton onClick={() => openModalRelease(numped)}><CheckCircleIcon className='actionIcon' sx={{ fontSize: '1.3rem', color: 'green' }} /></IconButton>
                            </Tooltip>
                            :
                            null
                        :
                        null
                }


                <Tooltip title='Visualiza o pedido'>
                    <IconButton onClick={() => openModalOrder(numped, emailDesc)}> <VisibilityIcon className='actionIcon' sx={{ fontSize: '1.5rem' }} /> </IconButton>
                </Tooltip>

                {
                    category ?

                        null
                        :
                        hasPermissions('administrador') ?
                            <Tooltip title='Oculta o pedido'>
                                <IconButton onClick={() => openModalHideOrder(numped, id_order)}> <DeleteIcon className='actionIcon' sx={{ fontSize: '1.3rem' }} /> </IconButton>
                            </Tooltip>
                            :
                            null
                }

                {
                    category ?
                        hasPermissions('administrador') ?
                            <Tooltip title='Recusa o pedido'>
                                <IconButton onClick={() => openModalRefuseOrder(numped)}> <NotInterestedIcon className='actionIcon' sx={{ fontSize: '1.3rem', color: 'red' }} /> </IconButton>
                            </Tooltip>
                            :
                            null
                        :
                        null
                }

                {
                    isMobile ?
                        category ?
                            <Tooltip title='Insere os anexo(s)'>
                                <IconButton onClick={(e) => e}> <InputFileUpload id_order={id_order} getActualOrder={getActualOrder} /> </IconButton>
                            </Tooltip>
                            :
                            null
                        :
                        null
                }

                {
                    isMobile ?
                        attachment ?
                            <Tooltip title="Visualiza os anexo(s)">
                                <IconButton onClick={() => openModalAttachment(id_order)}>
                                    <AttachFileIcon />
                                </IconButton>
                            </Tooltip>

                            :
                            null
                        :
                        null
                }
            </ResponsiveActionBox>
        )
    }

    const releaseOrder = async () => {
        const oid_enterprise = sessionStorage.getItem("oid_enterprise")
        const body = {
            'numped': orderNumber,
            'oid_enterprise': oid_enterprise
        }
        setLoader(true)
        const res = await api.post('/liberapedido/', body)
        if (res.status === 200) {
            const data = res.data
            if (data.status === false) {
                toast.error(data.message)
            } else {
                toast.success(data.message)
            }


        }
        setModalStatus(false)
        getActualOrder()
        getNextOrder()
        setLoader(false)
    }

    const getJustifications = async () => {

        setLoader(true)
        const res = await api.get('/justificacoes/')
        if (res.status === 200) {
            const data = res.data
            setJustifications(data)

        }
        setLoader(false)


    }

    const getActualOrder = async (load) => {

        if (load) setLoader(true)
        setCircleStatus(true)
        const res = await api.get('/pedidoatual/')
        if (res.status === 200) {
            const data = res.data
            setRowActual(ordenedList(data))

        }
        setLoader(false)
        setCircleStatus(false)

    }

    const getNextOrder = async (load) => {

        if (load) setLoader(true)
        setCircleStatus(true)
        const res = await api.get('/pedidoproximo/')
        if (res.status === 200) {
            const data = res.data
            setRowNext(ordenedList(data))

        }
        setLoader(false)
        setCircleStatus(false)

    }

    const hideOrder = async () => {
        const body = {
            'numped': orderNumber,
            'id_order': state.id_order
        }
        setLoader(true)
        const res = await api.post('/ocultapedido/', body)
        if (res.status === 200) {
            const data = res.data
        }

        setModalHide(false)
        getActualOrder()
        getNextOrder()
        setLoader(false)

    }

    const openModalRelease = (num) => {
        setOrderNumber(num)
        setModalStatus(true)
    }

    const closeModalRelease = () => {
        setModalStatus(false)
    }

    const closeModalHide = () => {
        setModalHide(false)
    }

    const closeModalRefuse = () => {
        setModalRefuse(false)
    }

    const closeModalAttachment = () => {
        setState({ modalAttachment: false })
    }

    const buttonConfirmRelease = () => {
        return (
            <Box>
                <ColorButton onClick={releaseOrder}>Sim</ColorButton>
            </Box>
        )
    }

    const buttonConfirmHide = () => {
        return (
            <Box>
                <ColorButton onClick={hideOrder}>Sim</ColorButton>
            </Box>
        )
    }

    const buttonConfirmRefuse = () => {
        return (
            <Box>
                <ColorButton onClick={refuseOrder}>Recusar</ColorButton>
            </Box>
        )
    }

    const changeTableSelect = (param) => (
        setTableSelect(param)
    )

    useEffect(() => {
        getJustifications()
        getActualOrder(true)
        getNextOrder(true)

        interval = setInterval(() => {
            getActualOrder(false)
            getNextOrder(false)
        }, 30000)

        return () => {
            clearInterval(interval)
        }
    }, [])


    return (
        <>
            <PainelContext.Provider value={{ state, setState }}>

                <DefaultModal dialogProps={{ maxWidth: 'xs' }} open={modalStatus} handleClose={closeModalRelease} content={<ModalText text={'Tem certeza que deseja aprovar este pedido ?'} />} action={buttonConfirmRelease()}
                    title={'Liberar pedido'} />

                <DefaultModal dialogProps={{ maxWidth: 'xs' }} open={modalHide} handleClose={closeModalHide} content={<ModalText text={'Tem certeza que deseja ocultar este pedido ?'} />} action={buttonConfirmHide()}
                    title={'Ocultar pedido'} />

                <DefaultModal dialogProps={{
                    sx: {
                        '& .MuiPaper-root': {
                            maxWidth: 'unset',
                            maxHeight: 'unset',
                            width: '95%',
                            height: '95%',
                            margin: '0px'
                        }
                    }

                }} open={state.modalAttachment} handleClose={closeModalAttachment} content={<AttachmentModal getActualOrder={getActualOrder} />}
                    title={'Lista de anexos'} />

                <DefaultModal
                    dialogProps={{ maxWidth: 'xl' }}
                    open={modalRefuse}
                    handleClose={closeModalRefuse}
                    content={
                        <Justifications justifications={justifications} otherMessage={otherMessage} getCheckboxValue={getCheckboxValue} />
                    }
                    action={buttonConfirmRefuse()}
                    title={'Recusar pedido'} />

                <DefaultModal dialogProps={{
                    sx: {
                        '& .MuiPaper-root': {
                            maxWidth: 'unset',
                            maxHeight: 'unset',
                            width: '95%',
                            height: '95%',
                            margin: '0px'
                        }
                    }

                }} open={openModal} handleClose={closeModal} content={<OrderFrame num={orderNumber} emailDesc={emailDesc} />}
                    title={' Visualizar pedido '} />

                {
                    isMobile ?

                        <Box className='mobile_order_box'>
                            <ColorButton className='mobile_order_btn'
                                fColor={tableSelect === 'analisados' ? '#8a0000' : true}
                                sColor='#c60000'
                                reverse={tableSelect === 'analisados' ? false : true}
                                onClick={() => changeTableSelect('analisados')}>
                                Pedidos a serem analisados
                            </ColorButton>

                            <ColorButton className='mobile_order_btn'

                                sColor='#c60000'
                                reverse={tableSelect === 'recusados' ? false : true}
                                onClick={() => changeTableSelect('recusados')}>
                                Pedidos liberados ou recusados
                            </ColorButton>
                        </Box>

                        : null
                }

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '3rem', height: '100%', overflow: 'hidden' }}>

                    <Box sx={{ flex: 1, minHeight: '1rem', display: isMobile ? tableSelect !== 'analisados' ? 'none' : null : null }}>
                        <Typography variant="h6" sx={{ color: '#c60000', display: isMobile ? 'none' : null }}>Pedidos a serem analisados</Typography>
                        <Table
                            sx={{
                                '& .center': {
                                    display: 'flex',
                                    justifyContent: 'flex-start',

                                },
                                '& .analysis': {
                                    backgroundColor: '#ffdd004a'
                                },
                                '& .order_bold': {
                                    fontWeight: 'bolder'
                                },
                                height: "100%",
                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    justifyContent: 'center',
                                },
                                '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                    color: 'black'
                                },

                            }}
                            paginationMode='client'
                            className='alterned_grid'
                            rows={rowActual}
                            columns={columnsA.filter(each => !(!isMobile && each.field === 'title'))}
                            pagination={false}
                            density='compact'
                            rowHeight={30}
                            getRowClassName={({ row }) => row.in_analysis ? 'is-analysis' : ''}
                            slots={{ toolbar: () => Toolbar(circleStatus) }}
                            mobile={true}
                            mobileProps={
                                {
                                    title: ['title'],
                                    secondary: [['Descricao']],
                                    actions: ['attachment', 'acoes'],
                                    dontReset: true
                                }
                            }

                        />
                    </Box>

                    <Box sx={{ flex: 1, minHeight: '1rem', display: isMobile ? tableSelect !== 'recusados' ? 'none' : null : null }}>
                        <Typography variant="h6" sx={{ color: '#c60000', display: isMobile ? 'none' : null }}>Pedidos liberados ou recusados</Typography>
                        <Table
                            sx={{
                                '& .center': {
                                    display: 'flex',
                                    justifyContent: 'center',
                                },
                                '& .order_bold': {
                                    fontWeight: 'bolder'
                                },
                                '& .refused.selected': {
                                    backgroundColor: '#ff00004a'
                                },
                                height: "90%",
                                '& .MuiDataGrid-columnHeaderTitleContainer': {
                                    justifyContent: 'center',
                                },
                                '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                    color: 'black'
                                },
                            }}
                            paginationMode='client'
                            className='alterned_grid'
                            rows={rowNext}
                            columns={columnsB.filter(each => !(!isMobile && each.field === 'title'))}
                            pagination={false}
                            density='compact'
                            rowHeight={30}
                            getRowClassName={({ row }) => row.refused ? 'is-refused' : ''}
                            slots={{ toolbar: () => Toolbar(circleStatus) }}
                            mobile={true}
                            mobileProps={
                                {
                                    title: ['title'],
                                    secondary: [['Descricao']],
                                    actions: ['attachment', 'acoes'],
                                    dontReset: true,
                                }

                            }
                        />
                    </Box>

                </Box>
            </PainelContext.Provider>
        </>
    )
}

export default Painel;