import React, { useEffect, useContext, useState } from 'react';
import { externalComponent } from '../AppRoutes';
import { api } from '../../services/api';
import Box from '@mui/material/Box';
import ColorButton from '../Buttons/ColorButton';
import { DistributionContext } from '.';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import { sugestionParser, aplicateSugestions } from './functionsCore';
import AplicateIcon from './aplicateIcon';
import RemoveIcon from './removeIcon';
import DefaultModal from '../Utils/DefaultModal';
import ModalText from './modalText';
import toast from 'react-hot-toast';

const SugestionVisualization = ({ hash }) => {

    const { setLoader } = useContext(externalComponent)
    const { state, setState } = useContext(DistributionContext)
    const [modalReset, setModalReset] = useState(false)
    const [modalApply, setModalApply] = useState(false)


    const resetSugestions = async () => {

        const reset = JSON.parse(state.dataToReset)
        setState({
            numTruck: reset.length, consolidatedStep4: reset, resetSugestions: !state.resetSugestions, colorSugestions: false,
            changedTables: !state.changedTables, updateStep4: !state.updateStep4
        })
        setModalReset(false)
        await getSugestions()

        Object.values(state.sugestions).map(item => Object.entries(item).map(([key, value]) => {

            value.action = false

        }))

    }

    const aplicateAllSugestions = () => {

        Object.values(state.sugestions).map(item => Object.entries(item).map(([key, value]) => {
            const tableNum = key.replace('table', '')
            let aplicate = value.action

            if (aplicate === false || aplicate === undefined) {
                aplicateSugestions(tableNum, value, state.consolidatedStep4)
                value.action = true
                setState({ consolidatedStep4: state.consolidatedStep4, changedTables: !state.changedTables, colorSugestions: !state.colorSugestions })
            }

        }))

        setModalApply(false)
        setState({ bksChange: !state.bksChange })
    }

    const getSugestions = async () => {
        setLoader(true)
        const res = await api.get(`/sugestao/${hash}/`)
        setLoader(false)
        if (res.status === 200) {
            let data = res.data.sugestions


            setState({ sugestions: data })
            return
        }
    }

    useEffect(() => {
        getSugestions()
    }, [])

    const ButtonConfirmReset = () => {
        return (
            <Box>
                <ColorButton className='realpadding' onClick={resetSugestions}>Sim</ColorButton>
            </Box>
        )
    }

    const openResetModal = () => {
        setModalReset(true)
    }

    const closeResetModal = () => {
        setModalReset(false)
    }

    const ButtonConfirmApply = () => {
        return (
            <Box>
                <ColorButton className='realpadding' onClick={aplicateAllSugestions}>Sim</ColorButton>
            </Box>
        )
    }

    const openApplyModal = () => {

        let allAplicate = true
        Object.values(state.sugestions).map(item => Object.entries(item).map(([key, value]) => {

            const aplicate = value.action
            if (aplicate !== true) {
                allAplicate = aplicate
            }

        }))

        if (allAplicate === true) {
            toast.error('Todas as sugestões já estão aplicadas !')
        } else {
            setModalApply(true)
        }
    }

    const closeApplyModal = () => {
        setModalApply(false)
    }

    return (
        <>
            <DefaultModal dialogProps={{ maxWidth: "xs" }} open={modalReset} handleClose={closeResetModal}
                title={' Confirmação '} content={<ModalText text={'Tem certeza que deseja resetar as sugestões ?'} />} action={<ButtonConfirmReset />} />

            <DefaultModal dialogProps={{ maxWidth: "xs" }} open={modalApply} handleClose={closeApplyModal}
                title={' Confirmação '} content={<ModalText text={'Tem certeza que deseja aplicar todas as sugestões ?'} />} action={<ButtonConfirmApply />} />

            <br /><br />
            {
                state.sugestions.length !== 0 ?
                    <Box sx={{ display: 'flex', justifyContent: 'row' }}>
                        {/* <ColorButton sx={{marginRight:'1rem'}} onClick={openResetModal}>Resetar sugestões</ColorButton>  */}

                        <ColorButton sx={{ marginRight: '1rem' }} onClick={openApplyModal}>Aplicar todas as sugestões</ColorButton>
                    </Box>
                    :
                    <></>
            }

            <List
                sx={{ width: '100%', maxWidth: '52rem', bgcolor: 'background.paper' }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    state.sugestions.length !== 0 ?
                        <ListSubheader sx={{ paddingLeft: '0px' }} component="div" id="nested-list-subheader">
                            Sugestões:
                        </ListSubheader>
                        :
                        <></>
                }
            >


                {
                    state.sugestions.length !== 0 ?


                        Object.values(state.sugestions).map(item => Object.entries(item).map(([key, value]) => {

                            const tableNum = key.replace('table', '')
                            const row = value.row


                            return (
                                <Box sx={{ display: 'flex', flexDirection: 'row', width: '55rem' }}>
                                    <Box sx={{ flex: 0.8 }}>
                                        <ListItemText primary={sugestionParser(tableNum, value)} />
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', flex: 0.2, justifyContent: 'flex-end' }}>
                                        <Box>
                                            <AplicateIcon key={tableNum} num={tableNum} infos={value} />
                                        </Box>

                                        <Box>
                                            <RemoveIcon key={tableNum - row.aro - row.medida} num={tableNum} infos={value} sugestions={state.sugestions} setState={setState} />
                                        </Box>
                                    </Box>
                                </Box>
                            )

                        }))
                        :
                        <></>
                }

            </List>


        </>
    )

}

export default SugestionVisualization;