import React, { useState, useEffect } from 'react'
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';
import ColorButton from '../Buttons/ColorButton';
import { api } from '../../services/api';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';

const MessageSend = ({ type, hash }) => {


    const [message, setMessage] = useState()
    const [disabled, setDisabled] = useState(false)
    const [data, setData] = useState({})
    const [send, setSend] = useState(true)
    const [selected, setSelected] = useState(type)

    const sendMessage = async () => {


        if (type === selected) {

            const data = {
                message: message,
                type: type,
                hash: hash
            }
            const res = await api.post('/mensagem/', data)
            if (res.status === 200) {
                toast.success('Mensagem enviada com sucesso!')
                setSend(!send)
            }

        } else {
            toast.error('Selecione a opção correta para enviar !')
        }

    }

    const getMessages = async () => {
        const res = await api.get(`/mensagem/${hash}`)
        let msg = ""
        if (res.status === 200) {
            setData(res.data)
            if (type === 'representative') {

                msg = res.data['adm']
                setMessage(msg)
                setDisabled(true)
                setSelected('adm')

            } else {

                msg = res.data['representative']
                setMessage(msg)
                setDisabled(true)
                setSelected('representative')
            }
        }
    }


    const AdmchangeMessage = () => {
        setSelected('adm')
        const msg = data['adm']
        setMessage(msg)
        if (type === 'representative') {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    const RpchangeMessage = () => {
        setSelected('representative')
        const msg = data['representative']
        setMessage(msg)
        if (type !== 'representative') {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    useEffect(() => {
        getMessages()
    }, [send])

    const changeMessage = (e) => {
        const msg = e.target.value
        setMessage(msg)
    }


    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                <Box sx={{ display: 'flex', border: '1px solid gray', borderRadius: '0.25rem', height: type === "representative" ? '2rem' : true }}>

                    <Tooltip title='Seleciona mensagem do adm.' placement="top-start">
                        <ColorButton reverse={selected === "adm" ? false : true} sx={{ height: '2rem', flex: 1 }} className='realpadding' onClick={AdmchangeMessage}>ADM</ColorButton>
                    </Tooltip>

                    <Tooltip title='Seleciona mensagem do representante.' placement="top-start">
                        <ColorButton reverse={selected === "representative" ? false : true} sx={{ height: '2rem', flex: 1 }} className='realpadding' onClick={RpchangeMessage}>Representante</ColorButton>
                    </Tooltip>

                </Box>

                <TextField

                    id="outlined-multiline-static"
                    multiline
                    rows={4}
                    value={message}
                    disabled={disabled}
                    onChange={changeMessage}
                    inputProps={{
                        maxLength: 5000,
                        style: {
                            outlineColor: 'black',
                            textWrap: 'wrap',
                        },
                    }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', height: type === "representative" ? '2rem' : true }}>
                    <Tooltip title='Envia uma mensagem que ficará salva.' placement="left-start">
                        <ColorButton sx={{ height: '2rem', fontSize: '0.75rem' }} className='realpadding' onClick={sendMessage}>Salvar mensagem</ColorButton>
                    </Tooltip>
                </Box>
            </Box>
        </>
    )
}

export default MessageSend