export const transferRow = (consolidated, tableName, table, row, equation, transferValue) => {
    //Se for toda a carga de envio
    if (equation === 0) {

        for (const each of consolidated) {

            if (each[table]) {

                let lines = each[table]['lines']

                let newLines = lines.filter(each => each.id !== row.id)
                each[table]['lines'] = newLines


            }

            if (each[tableName]) {
                let lines = each[tableName]['lines']
                let newLines = lines.filter(each => each.id !== row.id)
                let current_line = lines.filter(each => each.id === row.id)
                let current_value = 0

                if (current_line.length !== 0) {

                    current_value = current_line[0].cargo
                }

                let current_row = row
                current_row['cargo'] = stringToInt(transferValue) + stringToInt(current_value)

                each[tableName]['lines'] = [...newLines, current_row]
            }

        }

    }
    //Se tiver que fazer uma subtracao de valores
    else if (equation !== 0) {

        for (const each of consolidated) {

            if (each[table]) {

                let lines = each[table]['lines']
                let newLines = lines.filter(each => each.id !== row.id)
                const current_row = row

                current_row.cargo = equation
                each[table]['lines'] = [...newLines, current_row]


            }

            if (each[tableName]) {

                let lines = each[tableName]['lines']
                let newLines = lines.filter(each => each.id !== row.id)
                let current_line = lines.filter(each => each.id === row.id)
                let current_value = 0

                if (current_line.length !== 0) {
                    current_value = current_line[0].cargo
                }

                const newValue = stringToInt(transferValue) + stringToInt(current_value)
                const current_row_other = row
                const newDict = { ...current_row_other, "cargo": newValue }

                each[tableName]['lines'] = [...newLines, newDict]

            }

        }

    }
}


const calculatePercentage = (totalValue, percentage) => {
    const result = totalValue * (percentage / 100);
    return result;
}

const arred = (x, value) => {
    const res = value % 4
    switch (res) {
        case 1:
            return x - 1
        case 3:
            return x + 1
        default:
            return x
    }
}

const firstDegreeEquation = (x) => {
    let rule = 0

    if (x >= 200 && x < 600) {
        rule = -0.00125 * x + 1.25;
    }

    if (x >= 600 && x < 900) {
        rule = -0.0005 * x + 0.8;
    }

    return rule;
}

//FUNCAO QUE CONTEM O ALGORITMO CRIADO PELO VITOR
export const distributionRules = (dataParam, filialSelected, filialSelectedVen, setState) => {
    let data = dataParam
    data.filter(each => !(Number(each[filialSelected]) > 1.25 * Number(each[filialSelectedVen])))

    let listMain = []
    let listPass = []
    const branch = filialSelected.replace('est', '')

    for (let i = 0; i < data.length; i++) {

        //Se nao houver DISP BKS ele vai para lista de baixo e carga vai para 0

        let reserv = stringToInt(data[i][`reserv${branch}`])
        let dispbks = stringToInt(data[i]['dispbks'])

        const checkDispbks = ('dispbks' in data[i])
        if (checkDispbks === false || data[i]['dispbks'] === undefined) {
            data[i]['cargo'] = ''
            listPass.push(data[i])
            continue
        }

        //Venda media mensal
        let vmm = data[i][filialSelectedVen]
        //Se o valor de venda da filial selecionada for zero ele vai para tabela principal
        if (!vmm) {
            listMain.push(data[i])
            continue
        }

        //Estoque de filiais
        const soma_filiais = Object.keys(data[i])
            .filter(key => key.startsWith('est'))
            .reduce((acc, key) => acc + data[i][key], 0);

        //Venda das filiais 
        const soma_vendas = Object.keys(data[i])
            .filter(key => key.startsWith('ven'))
            .reduce((acc, key) => acc + data[i][key], 0);

        let estoque_atual = data[i][filialSelected]
        if (estoque_atual === undefined) {
            estoque_atual = 0
        }


        let new_vmm = 0
        if (vmm <= 16) {
            new_vmm = vmm * 1.35
        }

        if (vmm > 16 && vmm < 200) {
            new_vmm = vmm
        }

        if (vmm >= 200) {
            const multiply = firstDegreeEquation(vmm)
            new_vmm = vmm * multiply
            new_vmm = Math.ceil(new_vmm)
        }


        //Calculo da carga de envio (Pneus)
        let valor_de_envio = new_vmm - estoque_atual - reserv
        //valor_de_envio = roundToOneDecimalPlace(valor_de_envio)
        valor_de_envio = Math.round(valor_de_envio)

        //Se valor da carga for menor que 4 o envio dela vira 4
        if (valor_de_envio > 0 && valor_de_envio < 4) {
            valor_de_envio = 4
        }

        if (valor_de_envio < 0) {
            data[i]['cargo'] = ''
            listPass.push(data[i])
            continue
        }

        if (valor_de_envio > dispbks) {
            valor_de_envio = dispbks
        }

        data[i]['cargo'] = valor_de_envio

        //Se VMM < 5, dont pass.
        if (vmm < 5) {
            listMain.push(data[i])
            continue
        }

        if (soma_filiais >= soma_vendas) {

            //Lista A
            //Calcula a porcentagem do valor total diponivel da BKS
            const calculaValor = calculatePercentage(dispbks, 30)

            if (valor_de_envio < calculaValor) {
                listPass.push(data[i])
            } else {
                listMain.push(data[i])
            }

        } else {

            //Lista B
            //Calcula a porcentagem do valor total diponivel da BKS
            const calculaValor = calculatePercentage(dispbks, 20)

            if (valor_de_envio < calculaValor) {
                listPass.push(data[i])
            } else {
                listMain.push(data[i])
            }
        }

    }

    for (const dict of listMain) {
        dict['type'] = 'A'
    }

    for (const dict of listPass) {
        dict['type'] = 'B'
    }

    //DEPBKS IGUAL A ZERO NAO PRECISA CONSTAR NA LISTA B
    const newPassList = listPass.filter(obj => obj.hasOwnProperty('dispbks') || obj.dispbks === 0)
    listPass = newPassList

    let sorted_tableA = sortTables(listMain)
    let sorted_tableB = sortTables(listPass)

    for (const each of sorted_tableA) {
        if (each['cargo']) {
            let estBranch = stringToInt(each[filialSelected])
            const cargo = stringToInt(each.cargo)
            const sumNumbers = estBranch + cargo
            let dispbks = stringToInt(each['dispbks'])
            let newCargo = arred(cargo, sumNumbers)
            each['cargo'] = newCargo

            if (newCargo >= dispbks) {
                each['cargo'] = dispbks
            }

            if (stringToInt(each['cargo']) <= 0) {
                each['cargo'] = ""
            }

        } else {
            each['cargo'] = ""
        }
    }

    for (const each of sorted_tableB) {
        if (each['cargo']) {
            let estBranch = stringToInt(each[filialSelected])
            const cargo = stringToInt(each.cargo)
            const sumNumbers = estBranch + cargo
            let dispbks = stringToInt(each['dispbks'])
            let newCargo = arred(cargo, sumNumbers)
            each['cargo'] = newCargo

            if (newCargo > dispbks) {
                each['cargo'] = dispbks
            }

            if (stringToInt(each['cargo']) <= 0) {
                each['cargo'] = ""
            }

        } else {
            each['cargo'] = ""
        }
    }

    const totalA = sorted_tableA.reduce((sum, obj) => sum + (obj?.cargo || 0), 0);
    const totalB = sorted_tableB.reduce((sum, obj) => sum + (obj?.cargo || 0), 0);
    const totalAB = totalA + totalB

    setState({ distrA: sorted_tableA, distrB: sorted_tableB, totalAB: totalAB })

}

export const sortTables = (table) => {
    const sorted_lines = table.sort((a, b) => {
        if (a.medida < b.medida) {
            return -1;
        } else if (a.medida > b.medida) {
            return 1;
        } else {
            if (a.aro < b.aro) {
                return -1;
            } else if (a.aro > b.aro) {
                return 1;
            } else {
                return 0;
            }
        }
    });

    let arr_lines = []
    sorted_lines.map(each => arr_lines.push(each))
    return arr_lines
}

export const stringToInt = (str) => {
    const parsedInt = parseInt(str, 10); // Specify radix 10 to ensure decimal conversion
    return isNaN(parsedInt) ? 0 : parsedInt;
}

export const updateCodproInfo = async (data, lines) => {

    for (let each of lines) {
        const codproInfos = data.filter(item => item.codpro === each.codpro)

        codproInfos.forEach(obj => {
            for (const [key, value] of Object.entries(obj).filter(([k, v]) => k !== 'codpro')) {
                each[key] = value
            }
        });
    }

}

export const compareDates = (date1, date2) => {
    const [year1, month1, day1] = date1.split('-').map(Number);
    const [year2, month2, day2] = date2.split('-').map(Number);

    const dateObject1 = new Date(year1, month1 - 1, day1);
    const dateObject2 = new Date(year2, month2 - 1, day2);

    return dateObject1 <= dateObject2;
}

export const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');

    return `${year}-${month}-${day}`;
}

export const removeSugestion = (sugestion) => {

}


//CRIADOR DE SUGESTOES
export const checkcam = (sugestion, codpro, check, cam, row) => {

    let rowStr = JSON.stringify(row)
    const newRow = JSON.parse(rowStr)

    if (!(codpro in sugestion))
        sugestion[codpro] = {}
    if (!(cam in sugestion[codpro]))
        sugestion[codpro][cam] = {}
    sugestion[codpro][cam] = { ...sugestion[codpro][cam], check: check, type: 'check' }

    if (!sugestion[codpro][cam].hasOwnProperty('row')) {
        sugestion[codpro][cam] = { ...sugestion[codpro][cam], row: newRow }
    }
}

export const deletecam = (sugestion, codpro, cam, row) => {

    let rowStr = JSON.stringify(row)
    const newRow = JSON.parse(rowStr)

    let changed = true

    try {
        const rowCheck = sugestion[codpro][cam]

        if (rowCheck.hasOwnProperty('add')) {
            delete sugestion[codpro][cam];
            changed = false
        }

    } catch (error) {

    }

    if (changed) {
        if (!(codpro in sugestion))
            sugestion[codpro] = {}
        if (!(cam in sugestion[codpro]))
            sugestion[codpro][cam] = {}
        sugestion[codpro][cam] = { delete: true, type: 'delete' }

        if (!sugestion[codpro][cam].hasOwnProperty('row')) {
            sugestion[codpro][cam] = { ...sugestion[codpro][cam], row: newRow }
        }
    }



}

export const altercam = (sugestion, codpro, qtd, cam, row) => {
    let rowStr = JSON.stringify(row)
    const newRow = JSON.parse(rowStr)

    qtd = stringToInt(qtd)
    if (!(codpro in sugestion))
        sugestion[codpro] = {}
    if (!(cam in sugestion[codpro]))
        sugestion[codpro][cam] = {}
    sugestion[codpro][cam] = { ...sugestion[codpro][cam], alter: qtd, type: 'alter' }

    if (!sugestion[codpro][cam].hasOwnProperty('row')) {
        sugestion[codpro][cam] = { ...sugestion[codpro][cam], row: newRow }
    }
}


export const addcam = (sugestion, codpro, qtd, cam, row) => {

    let rowStr = JSON.stringify(row)
    const newRow = JSON.parse(rowStr)

    qtd = stringToInt(qtd)
    if (!(codpro in sugestion))
        sugestion[codpro] = {}
    if (!(cam in sugestion[codpro]))
        sugestion[codpro][cam] = {}
    sugestion[codpro][cam] = { add: qtd, type: 'add' }

    if (!sugestion[codpro][cam].hasOwnProperty('row')) {
        sugestion[codpro][cam] = { ...sugestion[codpro][cam], row: newRow }
    }
}

//APLICADORES DE SUGESTAO
export const alterRow = (row, lines, value) => {

    for (const line of lines) {

        if (line['codpro'] === row['codpro']) {
            line['cargo'] = value

        }
    }
}

export const checkedRow = (num, row, data, check) => {
    const table = data.find(each => each.hasOwnProperty(`table${num}`))
    const lines = table[`table${num}`].lines

    for (const line of lines) {
        if (line['codpro'] === row['codpro']) {
            line['checked'] = check
        }
    }
}

export const deleteRow = (num, row, data) => {

    const table = data.find(each => each.hasOwnProperty(`table${num}`))
    const lines = table[`table${num}`].lines
    const filteredLines = lines.filter(item => item.codpro !== row.codpro);
    table[`table${num}`].lines = filteredLines

}

//APLICA TODOS
export const aplicateSugestions = (num, infos, data) => {
    const type = infos.type
    let row = infos.row

    const table = data.find(each => each.hasOwnProperty(`table${num}`))
    const lines = table[`table${num}`].lines
    const check = String(infos.check)
    const value = infos.alter

    const hasCodpro = lines.some(each => each["codpro"] === infos.row.codpro);

    if ('delete' in infos) {
        deleteRow(num, row, data)
        return
    }

    if ('add' in infos) {

        if (hasCodpro) {
            alterRow(row, lines, infos.row.cargo)
        } else {
            const addValue = infos.add
            row.cargo = addValue
            table[`table${num}`].lines = [...lines, row]
        }

    }

    if ('alter' in infos) {
        alterRow(row, lines, value)
    }

    if ('check' in infos) {
        checkedRow(num, row, data, check)
    }

    //    switch(type){

    //         case 'delete':
    //                 deleteRow(num,row,data)

    //         break;

    //         case 'add':

    //             table[`table${num}`].lines = [...lines,row] 
    //         break;

    //         case 'alter':

    //             //condicao criar e alterar
    //             if(infos.hasOwnProperty('add')){
    //                 row.cargo = value
    //                 table[`table${num}`].lines = [...lines,row]

    //             }

    //             if(infos.hasOwnProperty('check')){
    //                 checkedRow(num,row,data,check)
    //             }


    //             alterRow(row,lines,value)
    //         break;

    //         case 'check':

    //             if(infos.hasOwnProperty('add')){
    //                 row.cargo = value
    //                 table[`table${num}`].lines = [...lines,row]

    //             }

    //             checkedRow(num,row,data,check)

    //             if(infos.hasOwnProperty('alter')){
    //                 alterRow(row,lines,value)
    //             }
    //         break;

    //    }

}

//APLICADOR REVERSO
export const ReverseSugestions = (num, infos, data) => {

    const type = infos.type
    const row = infos.row

    const table = data.find(each => each.hasOwnProperty(`table${num}`))
    const lines = table[`table${num}`].lines
    const ogValue = row.cargo

    let check = ''
    if (infos.check === true) {
        check = 'false'
    } else {
        check = 'true'
    }

    if ('delete' in infos) {
        table[`table${num}`].lines = [...lines, row]
    }

    if ('add' in infos) {
        deleteRow(num, row, data)
    }

    if ('alter' in infos) {
        alterRow(row, lines, ogValue)
    }

    if ('check' in infos) {
        checkedRow(num, row, data, check)
    }

    // switch(type){

    //      case 'delete':
    //         row.cargo = ogValue
    //         table[`table${num}`].lines = [...lines,row]
    //      break;

    //      case 'add':
    //         deleteRow(num,row,data)
    //      break;

    //      case 'alter':

    //         if(infos.hasOwnProperty('add')){
    //             deleteRow(num,row,data)
    //         }else{

    //             alterRow(row,lines,ogValue)
    //         }

    //         if(infos.hasOwnProperty('check')){
    //             checkedRow(num,row,data,check)
    //         }

    //      break;

    //      case 'check':
    //          checkedRow(num,row,data,check)

    //         if(infos.hasOwnProperty('add')){
    //             deleteRow(num,row,data)
    //         }

    //          if(infos.hasOwnProperty('alter')){
    //             alterRow(row,lines,ogValue)
    //         }


    //      break;

    // }

}

//PARSEADOR DE SUGESTOES
export const sugestionParser = (num, info) => {
    const type = info.type
    const row = info.row
    const aroMed = `"${row.aro} - ${row.medida} - ${row.codpro}"`
    const qtd = info.alter

    let checkText = ''
    if (info.check === true || info.check === 'true') {
        checkText = 'Priorizou'
    } else {
        checkText = 'Despriorizou'
    }

    let textAlter = ''
    switch (type) {

        case 'add':
            return `Adicionou ${row.cargo} do ${aroMed} no caminhão ${num}`

        case 'alter':

            let from = row.cargo
            let to = qtd

            return `alterou o envio de ${from} da ${aroMed} para ${to} no caminhão ${num}`

        case 'check':
            let checkFinalText = ''

            if (info.hasOwnProperty('alter')) {
                textAlter = `${checkText} e alterou`
                checkFinalText = `${textAlter} o envio de ${row.cargo} da ${aroMed} para ${qtd} no caminhão ${num}`
            }

            else if (info.hasOwnProperty('add')) {
                textAlter = `Adicionou ${row.cargo} e ${checkText.toLowerCase()}`
                checkFinalText = `${textAlter} ${aroMed} no caminhão ${num}`

            } else {
                checkFinalText = `${checkText} ${aroMed} no caminhão ${num}`

            }

            return checkFinalText

        case 'delete':
            return `Deletou ${aroMed} no caminhão ${num}`

    }
}